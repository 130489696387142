import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { Section, Title, Text, Box, Span } from "../components/Core";
import { langKeyDefault } from "../../buildVariables";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

export const FaqPageTemplate = ({
  title,
  subtitle,
  categories,
  noanswer,
  contact,
  langKey,
}) => {
  return (
    <section className="section">
      <Section className="pb-0">
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="12">
              <Title>{title}</Title>
              <Box>
                <Text mr={3}>{subtitle}</Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section pt={["40px !important", "40px !important", "80px !important"]}>
        <Container>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={categories[0].name}
          >
            <Row>
              <Col md="4" className="mb-5 mb-md-0">
                <NavStyled className="flex-column mr-md-5">
                  {categories.map((category) => (
                    <Nav.Link key={category.name} eventKey={category.name}>
                      {category.name}
                    </Nav.Link>
                  ))}
                </NavStyled>
              </Col>
              <Col md="8">
                <Tab.Content>
                  {categories.map((category) => (
                    <Tab.Pane key={category.name} eventKey={category.name}>
                      <Box>
                        {category.faq.map((faq) => (
                          <Box mb={4} key={faq.question}>
                            <Title variant="card" mb={2} fontSize="24px">
                              {faq.question}
                            </Title>
                            <Text variant="small">{faq.answer}</Text>
                          </Box>
                        ))}
                        <Box mb={4}>
                          {noanswer + " "}
                          <Link
                            to={
                              "/" +
                              (langKey === langKeyDefault
                                ? ""
                                : langKey + "/") +
                              "contact"
                            }
                          >
                            <Span color="primary">{contact}</Span>
                          </Link>
                          {"."}
                        </Box>
                      </Box>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Section>
    </section>
  );
};

// TermsPageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// };

const FaqPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark>
      <FaqPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        noanswer={post.frontmatter.noanswer}
        contact={post.frontmatter.contact}
        categories={post.frontmatter.categories}
        langKey={post.fields.langKey}
      />
    </PageWrapper>
  );
};

// TermsPage.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default FaqPage;

export const FaqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      frontmatter {
        title
        subtitle
        noanswer
        contact
        categories {
          name
          faq {
            question
            answer
          }
        }
      }
    }
  }
`;
